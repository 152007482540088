import { Box } from "@chakra-ui/react";
import { PlasmicCanvasContext } from "@plasmicapp/loader-nextjs";
import React, { useContext, useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player/lazy";
import { v4 as uuidv4 } from "uuid";

interface IProps {
  className?: string;
  src?: string;
  autoplay?: boolean;
  ignoreAspectRatio?: boolean;
  controls?: boolean;
  iconColor?: string;
  noninteractive?: boolean;
  playsInline?: boolean;
  playMuted?: boolean;
  loop?: boolean;
  // setControlContextData?: (data: any) => void;
}

const EmbeddedVideoPlasmic = ({
  className,
  src,
  autoplay = false,
  ignoreAspectRatio = false,
  controls = true,
  iconColor = "#6863F2",
  noninteractive = false,
  playsInline = false,
  playMuted = false,
  loop = false,
  // setControlContextData,
}: IProps) => {

  // Shorthand conditionals
  const disableAspectRatio = noninteractive && ignoreAspectRatio;
  const playAuto = autoplay || noninteractive;

  const [id, setId] = useState(null);
  
  const [isPlaying, setIsPlaying] = useState(playAuto);
  const [aspectRatio, setAspectRatio] = useState(1.777);

  const ref = useRef(null);
  const inEditor = useContext(PlasmicCanvasContext);

  // Generate a new ID if it doesn't exist
  useEffect(() => {
    if (!id) {
      setId(uuidv4());
    }
  }, [id]);

  const getAspect = (asset) => {
    const { width, height } = asset;
    const aspect = width / height;
    return aspect;
  };

  const handleReady = () => {
    const player = ref.current.getInternalPlayer();
    const video = player.data.media.assets[0];
    const aspect = getAspect(video);
    setAspectRatio(aspect);
  };

  const handlePlay = () => {
    setIsPlaying(true);
    const player = ref.current.getInternalPlayer();
    const video = player.data.media.assets[0];
    const aspect = getAspect(video);
    setAspectRatio(aspect);
  };
  
  const handleReload = () => {
    setId(uuidv4());
  };

  const [isSSR, setIsSSR] = useState(true);

  useEffect(() => {
    setIsSSR(false);
  }, []);
    
  // Autoplay prop update (in Plasmic editor)
  useEffect(() => {
    if (inEditor && !!ref.current) {
      if (playAuto) {
        handlePlay();
        ref.current.getInternalPlayer().mute();
      } else {
        setIsPlaying(false);
        handleReload();
      }
    }
  }, [autoplay, noninteractive]);

  // Force reload of component in Plasmic editor other key props change
  useEffect(() => {
    if (inEditor) {
      handleReload();
    }
  }, [iconColor, loop]);
  
  return (
    <Box
      className={`video-player ${className}`}
      pos="relative"
      sx={{
        aspectRatio: `${disableAspectRatio ? "auto" : aspectRatio}`,
      }}
      width="100%"
    >
      {!!src && !isSSR && (
        <ReactPlayer
          key={id}
          ref={ref}
          url={src}
          controls={controls && !noninteractive}
          playing={isPlaying}
          onReady={() => {
            handleReady();
          }}
          onPlay={() => {
            handlePlay();
          }}
          onPause={() => {
            setIsPlaying(false);
          }}
          muted={playMuted || playAuto}
          playsinline={playsInline || noninteractive}
          loop={loop && playAuto}
          width="100%"
          height="100%"
          config={{
            wistia: {
              playerId: `video-player-${id}`,
              options: {
                playerColor: iconColor,
                controlsVisibleOnLoad: false,
                copyLinkAndThumbnailEnabled: false,
                endVideoBehavior: loop && playAuto ? "loop" : "default",
                //playButton: controls && !noninteractive, // Crashes Plasmic
                wmode: "transparent",
                fitStrategy: disableAspectRatio ? "cover" : "contain",
                chromeless: false,
                videoFoam: true,
                fullscreenButton: !noninteractive,
              },
            },
          }}
        />
      )}
      {noninteractive && (
        <Box
          pos="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
        />
      )}
      {/* Inline styles to make prop updates dynamic in Plasmic */}
      <style type="text/css">{`
        ${!!id && disableAspectRatio ? `
          #video-player-${id} > * {
            width: 100%!important;
            height: 100%!important;
          }
          #video-player-${id} video {
            object-fit: cover!important;
          }
        ` : ""}
        ${!!id && (!controls || noninteractive) ? `
          #video-player-${id} .w-big-play-button {
            display: none!important;
          }
        ` : ""}
      `}</style>
    </Box>
  );
};

export default EmbeddedVideoPlasmic;
