import { Box } from "@chakra-ui/react";
import React, { useMemo } from "react";

/** Convert a #RRGGBB (or #RGB) hex color to "rgba(r,g,b,alpha)" */
function hexToRgba(hexColor: string, alpha: number): string {
  let c = hexColor.replace("#", "");
  if (c.length === 3) {
    // handle shorthand #RGB => #RRGGBB
    c = c[0] + c[0] + c[1] + c[1] + c[2] + c[2];
  }
  const r = parseInt(c.slice(0, 2), 16);
  const g = parseInt(c.slice(2, 4), 16);
  const b = parseInt(c.slice(4, 6), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

interface CutCornerCardProps {
  children?: React.ReactNode;
  className?: string;
  glowStickColor?: string; // e.g. "#BA66FF"
  backgroundColor?: string;
  hideTickMarks?: boolean;
}

/**
 * A CutCornerCard with six breakpoints (Desktop Normal/Taller, Tablet Normal/Taller, Mobile, Small Mobile),
 * plus a UNIQUE pulsing glowstick animation for each instance.
 */
export function CutCornerCard({
  children,
  className,
  glowStickColor = "#BA66FF",
  backgroundColor = "#2C2B27",
  hideTickMarks = false,
}: CutCornerCardProps) {
  // 1) Convert your glow color to a few RGBA strings at different opacities
  const glowColor1 = useMemo(
    () => hexToRgba(glowStickColor, 0.3),
    [glowStickColor]
  );
  const glowColor2 = useMemo(
    () => hexToRgba(glowStickColor, 0.6),
    [glowStickColor]
  );
  const glowColor3 = useMemo(
    () => hexToRgba(glowStickColor, 0.3),
    [glowStickColor]
  );

  // 2) Generate a unique keyframe name for this card instance
  //    e.g. "pulseGlow-abcdef"
  const uniquePulseName = useMemo(
    () => `pulseGlow-${Math.random().toString(36).slice(2)}`,
    []
  );

  // 3) Define nominal sizes & aspect ratios for each breakpoint
  const shapeDimensions = {
    desktopNormal: { w: 426, h: 428 },
    desktopTaller: { w: 426, h: 520 },
    tabletNormal: { w: 336, h: 408 },
    tabletTaller: { w: 336, h: 520 },
    mobile: { w: 341, h: 202 },
    smallMobile: { w: 341, h: 230 },
  };

  const ratioDesktopNormal =
    shapeDimensions.desktopNormal.w / shapeDimensions.desktopNormal.h;
  const ratioDesktopTaller =
    shapeDimensions.desktopTaller.w / shapeDimensions.desktopTaller.h;
  const ratioTabletNormal =
    shapeDimensions.tabletNormal.w / shapeDimensions.tabletNormal.h;
  const ratioTabletTaller =
    shapeDimensions.tabletTaller.w / shapeDimensions.tabletTaller.h;
  const ratioMobile = shapeDimensions.mobile.w / shapeDimensions.mobile.h;
  const ratioMobileSmall =
    shapeDimensions.smallMobile.w / shapeDimensions.smallMobile.h;

  return (
    <Box
      className={className}
      position="relative"
      display="inline-block"
      overflow="visible"
      // Overall drop-shadow for the entire card
      filter="drop-shadow(10px 10px 20px rgba(0,0,0,0.3))"
      /**
       * The 6 breakpoints:
       *   1) Desktop normal (≥1106px)
       *   2) Desktop taller (1025–1105px)
       *   3) Tablet normal (801–1024px)
       *   4) Tablet taller (641–800px)
       *   5) Mobile (≤640px)
       *   6) Small mobile (<450px)
       */
      sx={{
        "@media (min-width:1106px)": {
          width: "400px",
          aspectRatio: String(ratioDesktopNormal),
        },
        "@media (min-width:1025px) and (max-width:1105px)": {
          width: "400px",
          aspectRatio: String(ratioDesktopTaller),
        },
        "@media (min-width:801px) and (max-width:1024px)": {
          width: "90vw",
          aspectRatio: String(ratioTabletNormal),
        },
        "@media (min-width:641px) and (max-width:800px)": {
          width: "90vw",
          aspectRatio: String(ratioTabletTaller),
        },
        "@media (max-width:640px)": {
          width: "90vw",
          aspectRatio: String(ratioMobile),
        },
        "@media (max-width:450px)": {
          aspectRatio: String(ratioMobileSmall),
        },
      }}
    >
      {/* ========== GLOW STICK ========== */}
      <Box
        position="absolute"
        top="0"
        left="3%"
        width="20px"
        height="55%"
        transform="translate(0%, 50%)"
        pointerEvents="none"
        zIndex={1}
        // The background is the base color at 100% alpha
        backgroundColor={hexToRgba(glowStickColor, 1.0)}
        // The initial box-shadow uses partial alpha
        boxShadow={[
          `-5px 0px 20px 0px ${glowColor1}`,
          `5px 0px 30px 0px ${glowColor2}`,
        ].join(", ")}
        sx={{
          // Use the unique animation name, so each card can have its own color
          animation: `${uniquePulseName} 4s infinite ease-in-out`,

          "@media (max-width:1024px) and (min-width:640px)": {
            left: "4%",
          },
          "@media (max-width:640px)": {
            left: "2.5%",
            width: "15px",
            height: "50%",
          },
        }}
      />

      {/* ========== The clipped shape ========== */}
      <Box
        position="absolute"
        inset="0"
        overflow="hidden"
        zIndex={2}
        background={backgroundColor}
        sx={{
          // Desktop polygon by default
          clipPath:
            "polygon(4.69% 33.64%, 2.35% 31.31%, 2.35% 7.01%, 7.04% 2.34%, 90.61% 2.34%, 92.96% 4.67%, 92.96% 30.84%, 92.96% 33.18%, 92.96% 57.24%, 92.96% 59.58%, 92.96% 88.32%, 88.26% 93%, 4.69% 93%, 2.35% 90.65%, 2.35% 76.4%, 4.69% 74.06%, 4.69% 33.64%)",

          // Tablet polygon @640–1024px
          "@media (max-width:1024px) and (min-width:640px)": {
            clipPath:
              "polygon(5.95% 42.65%, 2.98% 40.2%, 2.98% 7.35%, 8.93% 2.45%, 88.1% 2.45%, 91.07% 4.9%, 91.07% 87.75%, 85.12% 92.65%, 5.95% 92.65%, 2.98% 90.2%, 2.98% 63%, 5.95% 60.54%, 5.95% 42.65%)",
          },
          // Mobile polygon @ ≤640px
          "@media (max-width:640px)": {
            clipPath:
              "polygon(3.44% 40.94%, 1.76% 38.07%, 1.76% 8.67%, 5.12% 2.97%, 93.05% 2.97%, 94.72% 5.82%, 94.72% 85.45%, 91.36% 91.15%, 3.44% 91.15%, 1.76% 88.41%, 1.76% 66.82%, 3.44% 63.96%, 3.44% 40.94%)",
          },
        }}
      >
        {/* Tick marks (optional) */}
        {!hideTickMarks && (
          <Box
            position="absolute"
            top="0"
            left="0"
            w="100%"
            h="100%"
            zIndex={5}
          >
            {/* Tick - top left */}
            <Box
              position="absolute"
              top="5%"
              left="10%"
              w="15%"
              sx={{
                "@media(max-width:1024px)": {
                  left: "10%",
                  w: "15%",
                },
                "@media(max-width:640px)": {
                  left: "6%",
                  w: "10%",
                },
              }}
            >
              <svg
                width="100%"
                viewBox="0 0 60 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M5.79 0L0 5.79H13.41L19.8 0H5.79Z" fill="#484743" />
                <path
                  d="M25.95 0L20.16 5.79H33.57L39.96 0H25.95Z"
                  fill="#484743"
                />
                <path d="M46.11 0L40.32 5.79H56L60 0H46.11Z" fill="#484743" />
              </svg>
            </Box>

            {/* Tick - bottom right */}
            <Box
              position="absolute"
              bottom="10%"
              right="10%"
              w="25%"
              sx={{
                "@media(max-width:1024px)": {
                  right: "12.5%",
                  bottom: "11.5%",
                },
                "@media(max-width:640px)": {
                  right: "7%",
                  bottom: "13%",
                },
                "@media(max-width:450px)": {
                  right: "7%",
                  bottom: "12%",
                },
              }}
            >
              <svg
                width="100%"
                viewBox="0 0 81 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 30H66.5L80 15.5V0"
                  stroke="#484743"
                  strokeWidth="1"
                />
              </svg>
            </Box>
          </Box>
        )}

        {/* Content area */}
        <Box
          position="absolute"
          inset="0"
          p="1.5rem 45px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          zIndex={3}
          color="#fff"
          sx={{
            "@media(max-width:1024px)": {
              p: "1rem 1rem",
            },
          }}
        >
          {children}
        </Box>
      </Box>

      {/* 4) Define the unique keyframes for this card instance */}
      <style jsx global>{`
        @keyframes ${uniquePulseName} {
          0%,
          100% {
            box-shadow: -5px 0px 15px 0px ${glowColor1},
              5px 0px 30px 0px ${glowColor2};
          }
          50% {
            box-shadow: -5px 0px 40px 0px ${glowColor2},
              5px 0px 50px 0px ${glowColor3};
          }
        }
      `}</style>
    </Box>
  );
}
