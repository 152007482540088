import { Box, Grid } from "@chakra-ui/react";
import * as React from "react";

export interface WhyCustomersChooseBladeProps {
  className?: string;
  /** Content for the FIRST (tall) clipped shape. */
  sectionOne?: React.ReactNode;
  /** Content for the SECOND clipped shape. */
  sectionTwo?: React.ReactNode;
  /** Content for the THIRD clipped shape. */
  sectionThree?: React.ReactNode;
}

/**
 * WhyCustomersChooseBlade with pulsing glow sticks.
 * Uses three color-coded glow bars:
 *  - Purple (#8743F5)
 *  - Gold (#FED141)
 *  - Green (#51D5C8)
 */
export function WhyCustomersChooseBlade({
  className,
  sectionOne,
  sectionTwo,
  sectionThree,
}: WhyCustomersChooseBladeProps) {
  return (
    <Box
      className={className}
      width="full"
      sx={{
        "@media(max-width:880px)": {
          px: 0,
        },
      }}
    >
      <Grid
        sx={{
          /* Single column below 880px */
          "@media(max-width:880px)": {
            display: "block", // disable grid entirely
            maxWidth: "650px", // optional
          },
          /* Two columns at ≥880px */
          "@media(min-width:880px)": {
            gridTemplateColumns: "repeat(2, 1fr)",
            gridAutoRows: "auto",
          },
          "@media(max-width:420px)": {
            display: "block", // fallback
          },
        }}
        gap={6}
        mx="auto"
      >
        {/* ========== 1) FIRST (Tall) SECTION ========== */}
        <Box
          position="relative"
          sx={{
            // Span 2 rows on desktop
            "@media(min-width:851px)": {
              gridRow: "span 2",
            },
          }}
        >
          {/* Glowstick (purple) */}
          <Box
            position="absolute"
            left=".75%"
            top="50%"
            transform="translateY(-25%)"
            height="50%"
            width="10px"
            zIndex={0}
            bg="#8743F5"
            boxShadow="
              -5px 0 30px 0 rgba(135, 67, 245, 0.7),
               5px 0 30px 0 rgba(135, 67, 245, 0.7)"
            sx={{
              // The pulsing animation
              animation: "pulseGlowPurple 4s infinite ease-in-out",
              "@keyframes pulseGlowPurple": {
                "0%, 100%": {
                  boxShadow: `
                    -5px 0 30px 0 rgba(135,67,245,0.4),
                     5px 0 30px 0 rgba(135,67,245,0.4)
                  `,
                },
                "50%": {
                  boxShadow: `
                    -5px 0 45px 0 rgba(135,67,245,0.8),
                     5px 0 45px 0 rgba(135,67,245,0.8)
                  `,
                },
              },
            }}
          />

          {/* Card shape (SVG) */}
          <Box position="relative" width="full" height="full">
            <Box
              as="svg"
              position="absolute"
              inset="0"
              width="full"
              height="full"
              zIndex={10}
              viewBox="0 0 609 645"
              fill="none"
              preserveAspectRatio="none"
            >
              <path
                d="M11 293L1 283V21L21 1H598.5L607 9.5V623L587 643H10.5L1 633.5V507L11 497V293Z"
                fill="black"
              />
              <path
                d="M11 643.5L1 633.5V507L11 497V293L1 283V21L21 1L598.5 1L607 9.5V623L587 643H10.5"
                stroke="#000"
                strokeWidth="0"
              />
            </Box>

            {/* Content Slot */}
            <Box position="relative" zIndex={20} p={6}>
              {sectionOne}
            </Box>
          </Box>
        </Box>

        {/* ========== 2) SECOND SECTION ========== */}
        <Box position="relative">
          {/* Glowstick (gold) */}
          <Box
            position="absolute"
            right=".75%"
            top="50%"
            transform="translateY(-70%)"
            height="40%"
            width="10px"
            zIndex={0}
            bg="#FED141"
            boxShadow="
              -5px 0 30px 0 rgba(254, 209, 65, 0.7),
               5px 0 30px 0 rgba(254, 209, 65, 0.7)"
            sx={{
              animation: "pulseGlowGold 4s infinite ease-in-out",
              "@keyframes pulseGlowGold": {
                "0%, 100%": {
                  boxShadow: `
                    -5px 0 30px 0 rgba(254, 209, 65,0.4),
                     5px 0 30px 0 rgba(254, 209, 65,0.4)
                  `,
                },
                "50%": {
                  boxShadow: `
                    -5px 0 45px 0 rgba(254, 209, 65, 0.8),
                     5px 0 45px 0 rgba(254, 209, 65, 0.8)
                  `,
                },
              },
            }}
          />

          {/* Card shape (SVG) */}
          <Box
            position="relative"
            width="full"
            height="full"
            sx={{
              "@media(max-width:880px)": {
                margin: "24px auto",
              },
            }}
          >
            <Box
              as="svg"
              position="absolute"
              inset="0"
              width="full"
              height="full"
              zIndex={10}
              viewBox="0 0 565 312"
              fill="none"
              preserveAspectRatio="none"
            >
              <path
                d="M1 11V290L21 310H553L563 300V188L553 178V90L563 80V21L543 1H11L1 11Z"
                fill="black"
                stroke="#000"
                strokeWidth="0"
              />
            </Box>

            {/* Content Slot */}
            <Box height="100%" position="relative" zIndex={20} p={6}>
              {sectionTwo}
            </Box>
          </Box>
        </Box>

        {/* ========== 3) THIRD SECTION ========== */}
        <Box position="relative">
          {/* Glowstick (green) */}
          <Box
            position="absolute"
            right=".75%"
            top="75%"
            transform="translateY(-70%)"
            height="40%"
            width="10px"
            zIndex={0}
            bg="#51D5C8"
            boxShadow="
              -5px 0 30px 0 rgba(81, 213, 200, 0.7),
               5px 0 30px 0 rgba(81, 213, 200, 0.7)"
            sx={{
              animation: "pulseGlowGreen 4s infinite ease-in-out",
              "@keyframes pulseGlowGreen": {
                "0%, 100%": {
                  boxShadow: `
                    -5px 0 30px 0 rgba(81,213,200,0.4),
                     5px 0 30px 0 rgba(81,213,200,0.4)
                  `,
                },
                "50%": {
                  boxShadow: `
                    -5px 0 45px 0 rgba(81,213,200,0.8),
                     5px 0 45px 0 rgba(81,213,200,0.8)
                  `,
                },
              },
            }}
          />

          {/* Card shape (SVG) */}
          <Box position="relative" width="full" height="full">
            <Box
              as="svg"
              position="absolute"
              inset="0"
              width="full"
              height="full"
              zIndex={10}
              viewBox="0 0 565 312"
              fill="none"
              preserveAspectRatio="none"
            >
              <path
                d="M1 11V290L21 310H553L563 300V249L553 239V151L563 141V21L543 1H11L1 11Z"
                fill="black"
                stroke="#000"
                strokeWidth="0"
              />
            </Box>

            {/* Content Slot */}
            <Box height="100%" position="relative" zIndex={20} p={6}>
              {sectionThree}
            </Box>
          </Box>
        </Box>
      </Grid>
    </Box>
  );
}
