import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

const ColorPalette = (props) => {
  const [colors, setColors] = useState([]);
  const [selected, setSelected] = useState({ name: "unset", value: null });

  useEffect(() => {
    const fetchColors = async () => {
      if (colors.length === 0) {
        const url =
          "https://codegen.plasmic.app/api/v1/loader/repr-v3/preview/" +
          process.env.NEXT_PUBLIC_PLASMIC_DESIGN_SYSTEM_PROJECT_ID;

        const response = await fetch(url, {
          headers: {
            "x-plasmic-api-project-tokens": `${process.env.NEXT_PUBLIC_PLASMIC_DESIGN_SYSTEM_PROJECT_ID}:${process.env.NEXT_PUBLIC_PLASMIC_DESIGN_SYSTEM_PROJECT_TOKEN}`,
          },
        });

        const responseJson = await response.json();
        const styleTokens = responseJson.site.styleTokens;
        const colorTokens = styleTokens.filter((e) => e.type === "Color");

        // Prepend 'unset' option if it doesn't exist
        // Not really sure why it wouldn't exists as it seems part of plasmics default selections / value but we can leave this for now.
        if (colorTokens.length > 0 && colorTokens[0].name !== "unset") {
          colorTokens.unshift({
            name: "unset",
            value: null,
          });
        }
        setColors(colorTokens);

        const index = colorTokens.findIndex((color) => color.value === props?.value);
        setSelected(colorTokens[index >= 0 ? index : 0] || { name: "unset", value: null });        
      }

      if(props.value === undefined) {
        setSelected({name: "unset", value: null});
      }

    };

    fetchColors();
  }, [props.value]);

  const updateColor = (event) => {
    setSelected(event);
    props.updateValue(event.value);
  };

  return (
    <Listbox value={selected?.value} onChange={updateColor}>
      <div
        style={{ background: "#F3F3F2", width: "100%", borderRadius: "7px" }}
      >
        <ListboxButton
          style={{
            width: "100%",
            padding: "4px",
            paddingRight: "10px",
            fontSize: "inherit",
            color: "#90908C",
            border: "none",
            borderRadius: "inherit",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            background: "#F3F3F2",
          }}
        >
          <span
            style={{
              backgroundColor: selected?.value,
              width: "24px",
              height: "24px",
              borderRadius: "4px",
              display: "inline-block",
              marginRight: "10px",
              verticalAlign: "middle",
            }}
          ></span>
          <div style={{ marginRight: "auto" }}>
            {selected?.name}
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={8}
            height={10}
            viewBox="0 0 24 24"
            strokeWidth="3"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            style={{
              verticalAlign: "middle",
              marginLeft: "10px",
              color: "##646464",
            }}
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <polyline points="6 9 12 15 18 9"></polyline>
          </svg>
        </ListboxButton>
      </div>
      <ListboxOptions
        style={{
          width: "66%",
          backgroundColor: "#fff",
          margin: 0,
          padding: "4px",
          position: "absolute",
          top: "50%",
          left: "33%",
          maxHeight: "300px",
          overflowY: "scroll",
          zIndex: "999",
          cursor: "pointer",
          borderRadius: "4px",
          boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.15)",
        }}
      >
        {colors.map((color) => (
          <ListboxOption
            key={uuidv4()}
            value={color}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "5px",
              padding: "4px",
            }}
          >
            <span
              style={{
                backgroundColor: color.value,
                width: "20px",
                height: "20px",
                borderRadius: "20%",
                display: "inline-block",
                marginRight: "10px",
              }}
            ></span>
            {color.name}
          </ListboxOption>
        ))}
      </ListboxOptions>
    </Listbox>
  );
};

export default ColorPalette;
